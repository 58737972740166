<template>
  <validation-observer ref="formCustomers">
    <form
      @submit.prevent="save"
    >
      <div class="animated fadeIn">
        <b-card class="px-md-2">
          <ResponseAlert ref="response" />
          <h3>{{ $route.meta.breadcrumb[2].text }}</h3>
          <b-row class="mt-2 justify-content-between">
            <b-col
              md="6"
            >
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="4">
                  <label
                    class="h5"
                    for="name"
                  >Nama Barang</label>
                </b-col>
                <b-col sm="8">
                  <b-form-input
                    id="name"
                    :value="vars.name"
                    disabled
                  />
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="4">
                  <label
                    class="h5"
                    for="name"
                  >Kode Barang</label>
                </b-col>
                <b-col sm="8">
                  <b-form-input
                    id="name"
                    :value="vars.no"
                    disabled
                  />
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="4">
                  <label
                    class="h5"
                    for="name"
                  >Stok</label>
                </b-col>
                <b-col sm="8">
                  <b-form-input
                    id="name"
                    :value="vars.availableToSell"
                    disabled
                  />
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="4">
                  <label
                    class="h5"
                    for="name"
                  >Satuan</label>
                </b-col>
                <b-col sm="8">
                  <b-form-input
                    id="name"
                    :value="vars.unit"
                    disabled
                  />
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="4">
                  <label
                    class="h5"
                    for="name"
                  >Harga Jual</label>
                </b-col>
                <b-col sm="8">
                  <b-form-input
                    id="name"
                    :value="vars.unitPriceFormat"
                    disabled
                  />
                </b-col>
              </b-row>
            </b-col>
            <b-col md="5">
              <b-row
                class="my-1"
              >
                <b-col sm="12">
                  <div class="image-input">
                    <div
                      v-if="vars.files.length > 0"
                      class="items-wrapper"
                    >
                      <div
                        v-for="file, index in vars.files"
                        :key="index"
                        class="item-wrapper"
                      >
                        <b-img
                          :src="getUrlOfFile(file)"
                          class="mb-2"
                          alt="image-items"
                        />
                        <b-button
                          v-if="!isDetailPage"
                          class="btn-primary"
                          @click.prevent="remove(index)"
                        >
                          <feather-icon
                            icon="XIcon"
                          />
                        </b-button>
                      </div>
                    </div>
                    <b-img
                      v-else
                      :src="getUrlOfFile(vars.photo, true)"
                      class="mb-2 w-50"
                    />
                    <validation-provider
                      #default="{ errors }"
                      name="Image"
                    >
                      <label
                        v-if="!isDetailPage"
                        for="file-input"
                      >
                        <input
                          id="file-input"
                          type="file"
                          accept="image/png, image/gif, image/jpeg, image/jpg"
                          hidden
                          multiple
                          @change="AddPhoto"
                        >
                        <feather-icon icon="PlusIcon" />
                        Upload Photo
                      </label>
                      <input
                        v-model="vars.files"
                        type="hidden"
                        :state="errors.length > 0 ? false:null"
                      >
                      <small class="text-danger text-center d-block m-auto">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                </b-col>
              </b-row>
              <b-row
                class="my-1"
              >
                <b-col cols="12">
                  <label
                    class="h5"
                    for="note"
                  >Deskripsi</label>
                  <validation-provider
                    #default="{ errors }"
                    name="Deskripsi"
                    rules="required"
                  >
                    <b-form-textarea
                      id="note"
                      v-model="models.description"
                      :disabled="isDetailPage"
                      :state="errors.length > 0 ? false:null"
                      rows="3"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col cols="12">
              <div
                class="accordion"
                role="tablist"
              >
                <div class="accordion-1-wrapper mb-1">
                  <b-button
                    block
                    variant="outline-primary"
                    class="text-left"
                    @click="$root.$emit('bv::toggle::collapse', 'accordion-1')"
                  >
                    Harga Distributor
                  </b-button>
                  <b-collapse
                    id="accordion-1"
                    visible
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <div class="p-2 border shadow-sm rounded">
                      <p
                        v-for="price, index in vars.detailSellingPrices.filter(item => item.type === 1)"
                        :key="index"
                      >
                        {{ price.levelName }}: Rp. {{ parseFloat(price.price).toLocaleString().replace(/[.,]/g, m => m === '.' ? ',' : '.') }}
                      </p>
                    </div>
                  </b-collapse>
                </div>
                <div class="accordion-2-wrapper">
                  <b-button
                    block
                    variant="outline-primary"
                    class="text-left"
                    @click="$root.$emit('bv::toggle::collapse', 'accordion-2')"
                  >
                    Harga Reseller
                  </b-button>
                  <b-collapse
                    id="accordion-2"
                    accordion="my-accordion2"
                    role="tabpanel"
                  >
                    <div class="p-2 border shadow-sm rounded">
                      <p
                        v-for="price, index in vars.detailSellingPrices.filter(item => item.type === 2)"
                        :key="index"
                      >
                        {{ price.levelName }}: Rp. {{ parseFloat(price.price).toLocaleString().replace(/[.,]/g, m => m === '.' ? ',' : '.') }}
                      </p>
                    </div>
                  </b-collapse>
                </div>
              </div>
            </b-col>
          </b-row>
          <div class="d-flex flex-column flex-md-row justify-content-center justify-content-md-end mt-3">
            <b-button
              type="button"
              variant="light"
              class="btn-min-width rounded mx-md-1 my-1 my-md-0"
              @click="$router.push({path: config.uri})"
            >
              Kembali
            </b-button>
            <b-button
              v-if="!isDetailPage"
              type="submit"
              variant="primary"
              class="btn-min-width rounded"
            >
              Simpan
            </b-button>
          </div>
        </b-card>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BImg, BFormInput, BFormTextarea, BCollapse,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, image,
} from '@validations'
import api from '@/utils/api'

// eslint-disable-next-line import/no-cycle
import { getUrlOfFile, uploadFile } from '@/utils/helpers'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  name: 'FormInventoryProduk',
  metaInfo: {
    title: 'Form Produk',
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormTextarea,
    BButton,
    BImg,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BCollapse,
    FeatherIcon,
  },
  data() {
    return {
      config: {
        uri: `/${this.$route.meta.link}`,
      },
      models: {
        id: this.$route.params.id,
        detailItemImage: [],
        description: '',
      },
      vars: {
        files: [],
        name: '',
        no: '',
        availableToSell: '',
        unit: '',
        unitPriceFormat: '',
        detailSellingPrices: [],
      },
      // Functions
      getUrlOfFile,
      required,
      image,
    }
  },
  computed: {
    isEditCustomerPage() {
      return this.$route.name === 'edit-customer'
    },
    isDetailPage() {
      return this.$route.name === 'detail-produk'
    },
  },
  created() {
    this.getById()
  },
  methods: {
    getById() {
      if (this.$route.params.id) {
        this.$axios.get(`${api.product}/${this.$route.params.id}`)
          .then(res => {
            const { data } = res.data
            this.vars.name = data.name
            this.vars.no = data.no
            this.vars.availableToSell = data.availableToSell
            // this.vars.unit = data.unit
            this.vars.unitPriceFormat = data.unitPriceFormat
            this.vars.files = data.detailItemImage
            this.vars.detailSellingPrices = data.detailSellingPrices

            this.models.description = data.description
          }).catch(() => this.$router.push({
            name: 'page404',
          }))
      }
    },

    remove(index) {
      this.vars.files = this.vars.files.filter((file, idx) => idx !== index)
    },

    AddPhoto(e) {
      const fileSizeLimit = 1024 * 1024 * 2 // limit 2 MB
      const { files } = e.target
      let exitLoop = false

      Array.from(files).forEach(file => {
        if (exitLoop) return
        if (file.size <= fileSizeLimit) {
          this.vars.files.push(file)
          e.target.value = ''
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed',
              text: 'Max file size is 2 MB',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          exitLoop = true
        }
      })
    },

    setValue() {
      this.$axios.put(api.product, this.models)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success update customer',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.$router.push(this.config.uri)
        })
        .catch(error => this.$refs.response.setResponse(error.response, 'danger'))
    },

    save() {
      this.$refs.formCustomers.validate().then(async success => {
        if (success) {
          // get file image url
          if (this.vars.files.length > 0) {
            this.$store.commit('app/UPDATE_SPINNER', true)

            for (let i = 0; i < this.vars.files.length; i++) {
              const url = typeof this.vars.files[i] === 'string' ? this.vars.files[i] : await uploadFile(this.vars.files[i])
              this.models.detailItemImage.push(url)
            }
            this.setValue()
          } else {
            this.setValue()
          }
        } else {
          window.scrollTo(0, 0)
        }
      })
    },
  },
}
</script>

<style scoped>
.items-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 20px 0;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.items-wrapper img:last-child {
  margin-right: 0;
}

.item-wrapper {
  position: relative;
  margin-right: 15px;
  height: 150px;
}

.item-wrapper img {
  flex: 0 0 auto;
  height: 100%;
  width: 12em;
  object-fit: contain;
}

.items-wrapper button {
  position: absolute;
  padding: 10px;
  border-radius: 100%;
  background: #B22A2E !important;
  top: -10px;
  right: -10px;
}
.items-wrapper::-webkit-scrollbar {
  height: 5px;
  border-radius: 15px;
}

.items-wrapper::-webkit-scrollbar-track {
  border-radius: 15px;    /* width of the entire scrollbar */
  background: lightgray;        /* color of the tracking area */
}

.items-wrapper::-webkit-scrollbar-thumb {
  background: rgb(180, 180, 180);
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid rgb(180, 180, 180);  /* creates padding around scroll thumb */
}
</style>
